import React from "react";
import GameTypes from "./GameTypes";

const SmallCard = (props) => {
	//console.log(props.data.Content);

	return (
		<div
			className={"smallCard type-" + props.data.Game}
			onClick={() => {
				// redirection vers la page d'édition de la carte
				window.location.href = "/edit/" + props.data.ID;
			}}
		>
			<div className="smallCard__header">
				<span className="game">
					{GameTypes[props.data.Game - 1].label}
				</span>
				<p className="title">{props.data.Title}</p>
			</div>
			{props.data.Game === 1 && props.data.Content !== "" && (
				<div className="content">
					<div className="qcm_list">
						<div className="qcm_list__item">
							<span
								className={
									props.data.Content[3].value === 0
										? "true"
										: ""
								}
							>
								A
							</span>
							{props.data.Content[0].value}
						</div>
						<div className="qcm_list__item">
							<span
								className={
									props.data.Content[3].value === 1
										? "true"
										: ""
								}
							>
								B
							</span>
							{props.data.Content[1].value}
						</div>
						<div className="qcm_list__item">
							<span
								className={
									props.data.Content[3].value === 2
										? "true"
										: ""
								}
							>
								C
							</span>
							{props.data.Content[2].value}
						</div>
					</div>
				</div>
			)}
			{props.data.Game === 4 && props.data.Content !== "" && (
				<div className="content">
					<div className="mots_interdits_list">
						{props.data.Content.map((item, index) => {
							return <span key={index}>{item.value}</span>;
						})}
					</div>
				</div>
			)}
			{props.data.Game === 5 && props.data.Content !== "" && (
				<div className="content">
					<div className="completez">
						<p>{props.data.Content.value}</p>
					</div>
				</div>
			)}
			{props.data.Game === 7 && props.data.Content !== "" && (
				<div className="content">
					<div className="remplacez">
						<p>{props.data.Content.value}</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default SmallCard;
