import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import GlobalStyle from "./theme/globalStyles";
import { AnimatePresence } from "framer-motion";
import "./theme/fonts.css";

import Home from "./routes/Home";
import Edit from "./routes/Edit";

const App = () => {
	const location = useLocation();

	useEffect(() => {
		document.title = "Le grand jeu des expressions";
	}, []);

	return (
		<>
			<GlobalStyle />
			<AnimatePresence mode="wait">
				<Routes location={location} key={location.pathname}>
					<Route path="/" element={<Home />} />
					<Route path="/edit/:id" element={<Edit />} />
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</AnimatePresence>
		</>
	);
};

export default App;
