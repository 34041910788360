import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import Cookie from "../components/Cookie";
import GameTypes from "../components/GameTypes";
import TextareaAutosize from "react-textarea-autosize";
import { motion } from "framer-motion";
import fetchUrl from "../fetchUrl";

const Edit = () => {
	const params = useParams();
	const isInitialMount = useRef(true);
	const [cardData, setCardData] = useState({
		Title: "",
		Game: 1,
		Content: "",
		Jeunes: 0,
	});
	const [meta, setMeta] = useState(null);
	const [gameTypeDropdownShow, setGameTypeDropdownShow] = useState(false);

	useEffect(() => {
		fetch(fetchUrl + "/card?id=" + params.id, {
			method: "GET",
			headers: {
				Authorization: Cookie.get("password"),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.message !== "Unauthorized") {
					setCardData(data.data);
					setMeta(data.meta);

					if (data.data.Game === 1 && data.data.Content.length) {
						setQCMTrueAnswer(data.data.Content[3].value);
					}
				} else {
					window.location.href = "/";
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [params.id]);

	const [QCMTrueAnswer, setQCMTrueAnswer] = useState(0);

	// Card update
	useEffect(() => {
		if (!cardData.ID) {
			return;
		}

		// Check for default content
		if (cardData.Content === "") {
			setCardData({
				...cardData,
				Content: GameTypes[cardData.Game - 1].defaultContent,
			});
			return;
		}

		if (isInitialMount.current) {
			isInitialMount.current = false;
			return;
		}

		// clone cardData
		let data = { ...cardData };

		data.Content = JSON.stringify(cardData.Content);

		//console.log("Updating card", data);

		fetch(fetchUrl + "/card", {
			method: "PUT",
			headers: {
				Authorization: Cookie.get("password"),
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.message !== "Unauthorized") {
					//console.log(data);
				} else {
					window.location.href = "/";
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [cardData]);

	const handleDelete = () => {
		if (!window.confirm("Etes-vous sûr de vouloir supprimer cette carte ?"))
			return;

		// Delete card
		fetch(fetchUrl + "/card/" + params.id, {
			method: "DELETE",
			headers: {
				Authorization: Cookie.get("password"),
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				window.location.href = "/";
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<>
			<div id="sidebar">
				<NavLink to={"/"}>Retour à la liste</NavLink>
				{meta !== null && (
					<>
						<NavLink to={"/edit/" + meta.previous}>
							Carte précédente
						</NavLink>
						<NavLink to={"/edit/" + meta.next}>
							Carte suivante
						</NavLink>
						<NavLink
							onClick={handleDelete}
							style={{ background: "#C01919" }}
						>
							Supprimer la carte
						</NavLink>
					</>
				)}
			</div>
			<div
				id="edit"
				onClick={(e) => {
					setGameTypeDropdownShow(false);
				}}
			>
				{cardData.ID && (
					<motion.div
						className={"bigCard type-" + cardData.Game}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.1 }}
					>
						<div className="bigCard__header">
							<div
								id="jeunes"
								title="Jeunes joueurs ?"
								className={cardData.Jeunes ? "true" : ""}
								onClick={(e) => {
									e.stopPropagation();
									setCardData({
										...cardData,
										Jeunes: cardData.Jeunes ? 0 : 1,
									});
								}}
							></div>
							<span
								className="game"
								onClick={(e) => {
									e.stopPropagation();
									setGameTypeDropdownShow(true);
								}}
							>
								{GameTypes[cardData.Game - 1].label}
								<div
									id="gameType_dropdown"
									className={
										gameTypeDropdownShow ? "show" : "hidden"
									}
								>
									{GameTypes.map((item, index) => (
										<div
											key={index}
											className="gameType_dropdown__item"
											onClick={(e) => {
												e.stopPropagation();
												setCardData({
													...cardData,
													Game: item.value,
													Content:
														GameTypes[
															item.value - 1
														].defaultContent,
												});
												setGameTypeDropdownShow(false);
											}}
										>
											{item.label}
										</div>
									))}
								</div>
							</span>
							<TextareaAutosize
								value={cardData.Title}
								placeholder="Ecrire le titre..."
								onChange={(e) =>
									setCardData({
										...cardData,
										Title: e.target.value,
									})
								}
								className="title"
							/>
						</div>
						{cardData.Game === 1 && (
							<div className="qcm_list">
								<div className="qcm_list__item">
									<span
										className={
											QCMTrueAnswer === 0 ? "true" : ""
										}
										onClick={() => {
											setQCMTrueAnswer(0);
											var content = cardData.Content;
											content[3].value = 0;
											setCardData({
												...cardData,
												Content: content,
											});
										}}
									>
										A
									</span>
									<TextareaAutosize
										value={
											cardData.Content
												? cardData.Content[0].value
												: ""
										}
										onChange={(e) => {
											var content = cardData.Content;
											content[0].value = e.target.value;
											setCardData({
												...cardData,
												Content: content,
											});
										}}
										placeholder="Proposition A"
									/>
								</div>
								<div className="qcm_list__item">
									<span
										className={
											QCMTrueAnswer === 1 ? "true" : ""
										}
										onClick={() => {
											setQCMTrueAnswer(1);
											var content = cardData.Content;
											content[3].value = 1;
											setCardData({
												...cardData,
												Content: content,
											});
										}}
									>
										B
									</span>
									<TextareaAutosize
										value={
											cardData.Content
												? cardData.Content[1].value
												: ""
										}
										onChange={(e) => {
											var content = cardData.Content;
											content[1].value = e.target.value;
											setCardData({
												...cardData,
												Content: content,
											});
										}}
										placeholder="Proposition B"
									/>
								</div>
								<div className="qcm_list__item">
									<span
										className={
											QCMTrueAnswer === 2 ? "true" : ""
										}
										onClick={() => {
											setQCMTrueAnswer(2);
											var content = cardData.Content;
											content[3].value = 2;
											setCardData({
												...cardData,
												Content: content,
											});
										}}
									>
										C
									</span>
									<TextareaAutosize
										value={
											cardData.Content
												? cardData.Content[2].value
												: ""
										}
										onChange={(e) => {
											var content = cardData.Content;
											content[2].value = e.target.value;
											setCardData({
												...cardData,
												Content: content,
											});
										}}
										placeholder="Proposition C"
									/>
								</div>
							</div>
						)}
						{cardData.Game === 4 && (
							<div className="mots_interdits_list">
								<div className="mots_interdits_list__item">
									<input
										type="text"
										value={
											cardData.Content
												? cardData.Content[0].value
												: ""
										}
										onChange={(e) => {
											var content = cardData.Content;
											content[0].value = e.target.value;
											setCardData({
												...cardData,
												Content: content,
											});
										}}
										placeholder="Mot interdit 1"
									/>
								</div>
								<div className="mots_interdits_list__item">
									<input
										type="text"
										value={
											cardData.Content
												? cardData.Content[1].value
												: ""
										}
										onChange={(e) => {
											var content = cardData.Content;
											content[1].value = e.target.value;
											setCardData({
												...cardData,
												Content: content,
											});
										}}
										placeholder="Mot interdit 2"
									/>
								</div>
								<div className="mots_interdits_list__item">
									<input
										type="text"
										value={
											cardData.Content
												? cardData.Content[2].value
												: ""
										}
										onChange={(e) => {
											var content = cardData.Content;
											content[2].value = e.target.value;
											setCardData({
												...cardData,
												Content: content,
											});
										}}
										placeholder="Mot interdit 3"
									/>
								</div>
								<div className="mots_interdits_list__item">
									<input
										type="text"
										value={
											cardData.Content
												? cardData.Content[3].value
												: ""
										}
										onChange={(e) => {
											var content = cardData.Content;
											content[3].value = e.target.value;
											setCardData({
												...cardData,
												Content: content,
											});
										}}
										placeholder="Mot interdit 4"
									/>
								</div>
								<div className="mots_interdits_list__item">
									<input
										type="text"
										value={
											cardData.Content
												? cardData.Content[4].value
												: ""
										}
										onChange={(e) => {
											var content = cardData.Content;
											content[4].value = e.target.value;
											setCardData({
												...cardData,
												Content: content,
											});
										}}
										placeholder="Mot interdit 5"
									/>
								</div>
							</div>
						)}
						{cardData.Game === 5 && (
							<div className="completez">
								<TextareaAutosize
									value={
										cardData.Content
											? cardData.Content.value
											: ""
									}
									onChange={(e) => {
										var content = cardData.Content;
										content.value = e.target.value;
										setCardData({
											...cardData,
											Content: content,
										});
									}}
									placeholder="Réponse..."
								/>
							</div>
						)}
						{cardData.Game === 7 && (
							<div className="remplacez">
								<TextareaAutosize
									value={
										cardData.Content
											? cardData.Content.value
											: ""
									}
									onChange={(e) => {
										var content = cardData.Content;
										content.value = e.target.value;
										setCardData({
											...cardData,
											Content: content,
										});
									}}
									placeholder="Réponse 1, réponse 2, réponse 3, ..."
								/>
							</div>
						)}
					</motion.div>
				)}
			</div>
		</>
	);
};

export default Edit;
