const GameTypes = [
	{
		value: 1,
		label: "QCM",
		defaultContent: [
			{ value: "" },
			{ value: "" },
			{ value: "" },
			{ value: 0 },
		],
	},
	{
		value: 2,
		label: "Mimez",
		defaultContent: {
			value: null,
		},
	},
	{
		value: 3,
		label: "Déssinez",
		defaultContent: {
			value: null,
		},
	},
	{
		value: 4,
		label: "Mots Interdits",
		defaultContent: [
			{ value: "" },
			{ value: "" },
			{ value: "" },
			{ value: "" },
			{ value: "" },
		],
	},
	{
		value: 5,
		label: "3en1 - Complétez",
		defaultContent: {
			value: "",
		},
	},
	{
		value: 6,
		label: "3en1 - Epelez",
		defaultContent: {
			value: null,
		},
	},
	{
		value: 7,
		label: "3en1 - Remplacez",
		defaultContent: {
			value: "",
		},
	},
];

export default GameTypes;
