import { createGlobalStyle } from "styled-components";
import "./destyle.css";

const GlobalStyle = createGlobalStyle`
  
  *{
    box-sizing: border-box;
  }

  html{
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    color: white;
    font-weight: 400;

    @media print {
        @page {
            size: 210mm 297mm;
        }
    }

    body{
      width: 100%;
      height: auto;
      min-height: 100%;
      background: #111111;
      margin: 0;
      padding: 0;

      #root{
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: safe center;
      }

      #login{
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        background: #222222;
        padding: 50px;
        border-radius: 10px;

        h2{
          line-height: 0;
          font-size: 30px;
          color: #777777;
          letter-spacing: 1px;
        }
        form{
          margin-top: 50px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          gap: 20px;

          input{
            background: white;
            padding: 10px;
            font-size: 20px;
            border: 2px solid transparent;
            color: black;
          }

          &.error{
            input{
              border: 2px solid red;
              color: red;

              &::placeholder{
                color: red;
              }
            }
          }
        }
      }

      button{
        width: 100%;
        text-transform: uppercase;
        background : #2EB55C;
        padding: 10px;
        font-size: 20px;
        display:flex;
        justify-content: center;
        align-items: center;
      }

      #cardsList{
        position: absolute;
        top: 0;
        right: 0;
        display: grid; /* (1) */
        grid-template-columns: repeat(auto-fit, 315px); /* (2) */
        grid-gap: 50px; /* (3) */
        justify-content: center; /* (4) */
        align-content: flex-start; /* (5) */
        overflow-y: scroll;
        overflow-x: hidden;
        width: calc(100% - 300px);
        height: 100%;
        padding: 50px;

        .smallCard{
          display: flex;
          flex-flow: column;
          background: white;
          width: 315px;
          height: 433px;
          cursor: pointer;
          box-shadow: black 0px 0px 100px;
          transition: all 0.3s ease;
          filter: brightness(0.8) grayscale(0.1);

          &:hover{
            transform: scale(1.1);
            box-shadow: black 0px 0px 200px;
            filter:none;
          }

          // QCM
          &.type-1{
            .smallCard__header{
              background: #E5366F;
            }
          }
          // Mimez
          &.type-2{
            .smallCard__header{
              background: #404F70;
              height: 100%;
            }
          }
          // Déssinez
          &.type-3{
            .smallCard__header{
              background: #1C7789;
              height: 100%;
            }
          }
          // Mots interdits
          &.type-4{
            .smallCard__header{
              background: #82655D;
            }
          }
          // 3en1 - Complétez
          &.type-5{
            .smallCard__header{
              background: #BF5C33;
            }
          }
          // 3en1 - Epelez
          &.type-6{
            .smallCard__header{
              background: #BF5C33;
              height: 100%;
            }
          }
          // 3en1 - Remplacez
          &.type-7{
            .smallCard__header{
              background: #BF5C33;
            }
          }

          .smallCard__header{
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 210px;
            position: relative;

            .game{
              position: absolute;
              font-family: "Cormorant", serif;
              font-size: 15px;
              letter-spacing: 1px; 
              top: 20px;
              text-transform: uppercase;
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: center;
              font-feature-settings: "pnum" on, "lnum" on;
            }

            .title{
              font-family: "Cormorant", serif;
              font-size: 26px;
              padding: 100px 30px;
              text-align: center;
              font-feature-settings: "pnum" on, "lnum" on;
            }
          }

          .content{
            display: flex;
            flex-flow: column;
            flex: 1;
          }
          .qcm_list{
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 100%;
            padding : 30px;

            .qcm_list__item{
              width: 100%;
              min-height: 15px;
              position: relative;
              display: flex;
              align-items: center;
              text-align: left;
              font-family: "Cormorant", serif;
              color: black;
              font-size: 14px;
              padding-left: 32px;

              span{
                position: absolute;
                left:0;
                color: #E5366F;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border: 1px solid;
                border-radius: 50%;
                cursor: pointer;

                &.true{
                  background: #E5366F;
                  color: white;
                }
              }

            }
          }

          .mots_interdits_list{
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 100%;
            padding : 20px;
            color: black;
            font-family: "Cormorant", serif;
            font-size: 20px;
          }

          .completez{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex: 1;

            p{
              width: 80%;
              font-family: "Cormorant", serif;
              font-size: 16px;
              color: black;
              text-align: center;
            }
          }

          .remplacez{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex: 1;
            height: 100%;

            p{
              width: 80%;
              font-family: "Cormorant", serif;
              font-size: 16px;
              color: black;
              text-align: center;
            }
          }
          
        }
      }

      #sidebar{
        position: fixed;
        z-index: 999;
        top:0;
        left:0;
        width: 300px;
        height: 100%;
        background: #222222;
        box-shadow: 0px 0px 100px black;
        display: flex;
        flex-flow: column;
        justify-content: start;
        align-items: center;
        padding: 20px;
        gap: 20px;

        h1{
          font-family: "Cormorant",serif;
          font-size: 32px;
          text-align: center;
        }

        .count{
          width: 100%;
          padding: 20px;
          font-size: 20px;
          text-align: center;
          font-weight: lighter;

          b{
            font-weight: bolder;
          }
        }

        input{
          width: 100%;
          padding: 10px;
          border: 1px solid #666666;
        }

        a{
          background: #444444;
          padding: 10px 20px;
          cursor: pointer;
          font-size: 16px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover{
            background: #555555;
          }
        }
      }

      #edit{
        position: absolute;
        top:0;
        right:0;
        width: calc(100% - 300px);
        min-height: 100%;
        overflow: hidden;
        padding: 50px;
        display: flex;
        justify-content: center;
        align-items: safe center;
        font-feature-settings: 'pnum' on, 'lnum' on;

        .bigCard{
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          align-items: center;
          background: white;
          width: 630px;
          height: 866px;
          box-shadow: black 0px 0px 100px;
          transform: scale(0.85);

          // QCM
          &.type-1{
            .bigCard__header{
              background: #E5366F;
            }
          }
          // Mimez
          &.type-2{
            .bigCard__header{
              background: #404F70;
              height: 100%;
            }
          }
          // Déssinez
          &.type-3{
            .bigCard__header{
              background: #1C7789;
              height: 100%;
            }
          }
          // Mots interdits
          &.type-4{
            .bigCard__header{
              background: #82655D;
            }
          }
          // 3en1 - Complétez
          &.type-5{
            .bigCard__header{
              background: #BF5C33;
            }
          }
          // 3en1 - Epelez
          &.type-6{
            .bigCard__header{
              background: #BF5C33;
              height: 100%;
            }
          }
          // 3en1 - Remplacez
          &.type-7{
            .bigCard__header{
              background: #BF5C33;
            }
          }

          .bigCard__header{
            display: flex;
            flex-flow: column;
            justify-content: center; 
            align-items: center;
            width: 100%;
            height: 420px;
            position: relative;
            overflow: hidden;

            #jeunes{
              position: absolute;
              top: -18px;
              right: -18px;
              width: 80px;
              height: 80px;
              border-radius: 50%;
              background: transparent;
              border: 8px solid rgb(255, 255, 0);
              cursor: pointer;
              transition: background 0.1s linear;

              &:hover{
                background: rgba(255, 255, 0, 0.5);
              }

              &.true{
                background: rgb(255, 255, 0);
              }
            }

            .game{
              position: absolute;
              font-family: "Cormorant", serif;
              font-size: 30px;
              letter-spacing: 2px; 
              top: 40px;
              text-transform: uppercase;
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              #gameType_dropdown{
                position: absolute;
                z-index: 999;
                top: 100%;
                width: 300px;
                flex-flow: column;
                background: #333333;
                font-family: "Open Sans", sans-serif;
                font-size: 16px;
                box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 50px;
                display: none;

                &.show{
                  display: flex;
                }

                .gameType_dropdown__item{
                  text-align: center;
                  cursor: pointer;
                  padding: 14px;

                  &:hover{
                    background: #555555;
                  }
                }
              }
            }

            .title{
              font-family: "Cormorant", serif;
              font-size: 52px;
              padding: 200px 60px;
              text-align: center;
              resize: none;
              overflow: hidden;

              &::placeholder{
                color: #ffffff;
                opacity: 0.75;
                font-style: italic;
                font-weight: lighter;
              }
            }
          }
          
          .qcm_list{
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 100%;
            padding : 50px;

            .qcm_list__item{
              width: 100%;
              position: relative;
              display: flex;
              align-items: center;

              span{
                position: absolute;
                left:0;
                color: #E5366F;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                border: 1px solid;
                border-radius: 50%;
                cursor: pointer;

                &.true{
                  background: #E5366F;
                  color: white;
                }
              }
              
              textarea{
                width: 100%;
                padding: 10px;
                padding-left: 70px;
                text-align: left;
                font-family: "Cormorant", serif;
                color: black;
                resize: none;

                &::placeholder{
                  font-style: italic;
                  opacity: 0.5;
                }
              }
            }
          }

          .mots_interdits_list{
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 100%;
            padding : 50px;

            input{
              width: 100%;
              font-family: "Cormorant", serif;
              font-size: 30px;
              color: black;
              resize: none;
              text-align: center;

              &::placeholder{
                font-style: italic;
              }
            }
          }

          .completez{
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 100%;
            flex: 1;

            textarea{
              width: 80%;
              min-height: 50px;
              font-family: "Cormorant", serif;
              font-size: 30px;
              color: black;
              resize: none;
              text-align: center;

              &::placeholder{
                font-style: italic;
              }
            }
          }

          .remplacez{
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 100%;
            flex: 1;


            textarea{
              width: 80%;
              min-height: 50px;
              font-family: "Cormorant", serif;
              font-size: 30px;
              color: black;
              resize: none;
              text-align: center;

              &::placeholder{
                font-style: italic;
              }
            }
          }
        }
      }
    }
  }
`;

export default GlobalStyle;
