import React, { useState } from "react";
import Login from "../components/Login";
import CardsList from "../components/CardsList";

const Home = () => {
	const [isLogged, setIsLogged] = useState(false);

	return (
		<>
			{!isLogged && <Login error={false} setIsLogged={setIsLogged} />}
			{isLogged && <CardsList />}
		</>
	);
};

export default Home;
