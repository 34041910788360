import React, { useState, useEffect, useRef, useCallback } from "react";
import { NavLink } from "react-router-dom";
import Cookie from "./Cookie";
import SmallCard from "./SmallCard";
import fetchUrl from "../fetchUrl";

const CardsList = () => {
	const [cards, setCards] = useState([]);
	const [count, setCount] = useState(null);
	const [filterCount, setFilterCount] = useState(null);

	const cardsListRef = useCallback((node) => {
		if (node !== null) {
			// restore scroll position from session storage
			const scrollPosition = sessionStorage.getItem("scrollPosition");
			if (scrollPosition) {
				//console.log("scrollPosition", scrollPosition, node);
				node.scrollTo(0, scrollPosition);
				sessionStorage.removeItem("scrollPosition");
			}
		}
	}, []);

	const getAllCards = () => {
		fetch(fetchUrl + "/cards?itemsPerPage=0", {
			method: "GET",
			headers: {
				Authorization: Cookie.get("password"),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.message !== "Unauthorized") {
					setCards(data.data);
				} else {
					window.location.href = "/";
				}
			})
			.catch((error) => {
				console.error(error);
			});

		getTotalCount();
	};

	const getTotalCount = () => {
		fetch(fetchUrl + "/cards/count", {
			method: "GET",
			headers: {
				Authorization: Cookie.get("password"),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.message !== "Unauthorized") {
					setCount(data);
					setFilterCount(data);
				} else {
					window.location.href = "/";
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	useEffect(() => {
		if (!sessionStorage.getItem("searchTitle")) {
			getAllCards();
		} else {
			var e = {
				target: {
					value: sessionStorage.getItem("searchTitle"),
				},
			};
			handleSearch(e);
			//sessionStorage.removeItem("searchTitle");
			getTotalCount();
		}
	}, []);

	const handleSearch = (e) => {
		if (e.target.value === "") {
			sessionStorage.removeItem("searchTitle");
			return getAllCards();
		}

		sessionStorage.setItem("searchTitle", e.target.value);

		fetch(fetchUrl + "/cards/search", {
			method: "POST",
			headers: {
				Authorization: Cookie.get("password"),
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				search: e.target.value,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.message !== "Unauthorized") {
					setCards(data);
					setFilterCount(data.length);
				} else {
					window.location.href = "/";
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleCreate = () => {
		fetch(fetchUrl + "/card/create", {
			method: "GET",
			headers: {
				Authorization: Cookie.get("password"),
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.message !== "Unauthorized") {
					window.location.href = "/edit/" + data;
				} else {
					window.location.href = "/";
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleScroll = (event) => {
		sessionStorage.setItem("scrollPosition", event.currentTarget.scrollTop);
	};

	return (
		<>
			<div id="sidebar">
				<h1>Le grand jeu des expressions</h1>
				<span className="count">
					{filterCount} / {count}
				</span>
				<input
					type="text"
					className="search"
					placeholder="Rechercher ..."
					onChange={handleSearch}
					value={sessionStorage.getItem("searchTitle") || ""}
				/>
				<NavLink onClick={handleCreate}>Ajouter une carte</NavLink>
			</div>
			{cards.length > 0 && (
				<div id="cardsList" onScroll={handleScroll} ref={cardsListRef}>
					{cards.map((item, index) => (
						<SmallCard key={index} data={item} />
					))}
				</div>
			)}
		</>
	);
};

export default CardsList;
