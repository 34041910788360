import React, { useState, useEffect, useCallback } from "react";
import Cookie from "./Cookie";
import fetchUrl from "../fetchUrl";

const Login = (props) => {
	const [isInit, setIsInit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(false);

	const handleSubmit = (event) => {
		event.preventDefault();
		if (isLoading) return;

		setIsLoading(true);

		tryLogin(event.target.password.value);
	};

	const tryLogin = useCallback(
		(password, auto = false) => {
			fetch(fetchUrl, {
				method: "GET",
				headers: {
					Authorization: password,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					if (data.message === "Unauthorized") {
						setError(true);
						setIsLoading(false);
						if (auto) {
							Cookie.erase("password");
							window.location.href = "/";
						}
					} else {
						Cookie.set("password", password, 1);
						props.setIsLogged(true);
					}
				})
				.catch((error) => {
					setError(true);
					Cookie.erase("password");
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[props]
	);

	useEffect(() => {
		if (Cookie.get("password")) {
			tryLogin(Cookie.get("password", true));
		} else {
			setIsInit(true);
		}
	}, [tryLogin]);

	return (
		<div
			id="login"
			style={isInit ? { display: "flex" } : { display: "none" }}
		>
			<h2>Connexion</h2>
			<form className={error ? "error" : ""} onSubmit={handleSubmit}>
				<input
					type="password"
					id="password"
					name="password"
					placeholder="Mot de passe ..."
				/>
				<button type="submit">
					{isLoading ? "..." : "Se connecter"}
				</button>
			</form>
		</div>
	);
};

export default Login;
